/* eslint-disable @typescript-eslint/no-unused-vars */
import { IPagingProps } from '../models';
import { http } from './http';

export interface IV1FeedBackFilters {}
export interface IV1FeedBack extends IPagingProps<IV1FeedBackFilters> {}

export const FeedBackApi = {
	v1FeedBack: async (limit: any, page: any, search: string) => {
		const res = await http.get('/v1/api/job-feedback', {
			params: {
				limit,
				page,
				['search']: search,
			},
		});
		return res;
	},
	v1FeedBackById: async (cvId: string) => {
		const res = await http.get(`/v1/api/job-feedback/${cvId}`);
		return res;
	},
	v1FeedbackOptimizeCV: async (limit: any, page: any, email: string, source: string) => {
		const res = await http.get('/v1/api/optimize-cv/anynomous-cv/feedback', {
			params: {
				limit,
				page,
				['filter.email']: `$ilike:${email}`,
				['filter.source']: `$ilike:${source}`,
			},
		});
		return res;
	},
};
