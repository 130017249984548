import { Input, PageHeader, Select, Typography } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { FeedBackApi } from '../../../core/apis/feedBack.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useTableUtil } from '../../../core/contexts';

interface FeedBackProps {}

export const FeedbackOptimizeCV: React.FC<FeedBackProps> = () => {
	const { limit, page, setTotalItems } = useTableUtil();
	const [search, setSearch] = useState('');
	const [source, setSource] = useState('');

	const feedBackQuery = useQuery(
		[
			'feedBack',
			{
				limit,
				page,
				search,
				source
			},
		],
		async () => {
			const { data } = await FeedBackApi.v1FeedbackOptimizeCV(limit, page, search, source);
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);
			setTotalItems(totalItems);
			return data.data;
		}
	);

	React.useEffect(() => {
		document.title = 'Feedback Optimize CV';
	}, []);

	return (
		<>
			<div className='fade-in'>
				<PageHeader
					title='Feedback Optimize CV'
					extra={[
						<Input
							className='w-full'
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							placeholder='Search Email...'
						/>,
						<Select
							className='w-48'
							placeholder='Source by'
							onChange={(value) => {
								setSource(value);
							}}
							options={[
								{ value: '', label: 'All' },
								{ value: 'website', label: 'Website' },
								{ value: 'email', label: 'Email' },
								{ value: 'social', label: 'Social' },
							]}
						/>,
					]}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>STT</div>,
								width: 30,
								dataIndex: 'index',
								key: 'index',
								render: (text, record, index) => {
									return <>{index + 1}</>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 100,
								dataIndex: 'email',
								key: 'email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'email', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');
									return <>{data ? moment(data).format('DD/MM/YYYY') : '-'}</>;
								},
							},
							{
								title: () => <div>Content Feedback</div>,
								width: 400,
								dataIndex: 'feedback',
								key: 'feedback',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'feedback', '');
									return (
										<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
											{data?.length > 0 ? (
												<Typography.Paragraph
													ellipsis={{
														rows: 2,
														expandable: true,
														symbol: 'Xem thêm',
													}}
													style={{ margin: 0 }}
												>
													{data}
												</Typography.Paragraph>
											) : (
												'-'
											)}
										</div>
									);
								},
							},
						]}
						data={feedBackQuery.data?.data || []}
						isLoading={feedBackQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
