/* eslint-disable @typescript-eslint/no-unused-vars */
import { Line } from '@ant-design/plots'; // Replace Pie with Line
import { Card } from 'antd';
import React from 'react';
import '../chart.css';

interface LineChartComponentsProps {
	title: string;
	data: any;
	xField: string;
	yField: string;
	seriesField: string;
}

const LineChartComponents: React.FC<LineChartComponentsProps> = ({ data, xField, yField, seriesField, title }) => {
	const config = {
		data,
		xField,
		yField,
		seriesField,
		tooltip: {
			formatter: (datum: any) => {
				return { name: datum[seriesField], value: datum[yField] };
			},
		},
		point: {
			size: 5,
			shape: 'circle',
		},
		smooth: true,
		interactions: [
			{
				type: 'marker-active',
			},
		],
	};
	
	return (
		<Card title={title} bordered={false} style={{ width: '100%' }}>
			<Line {...config} className='chart' />
		</Card>
	);
};

export default LineChartComponents;
