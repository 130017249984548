/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, PageHeader, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { statisticalApi } from '../../../core/apis/statistical.api';
import LineChartComponents from './components/LineChart';
import LoadingComponents from './components/Loading';

import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const Statistical = ({}: {}) => {
	const [fromDate, setFromDate] = useState(() => moment().startOf('year'));
	const [toDate, setToDate] = useState(() => moment());
	const queryClient = useQueryClient();

	const [loading, setLoading] = useState(false);
	const usedFeatureQuery = useQuery(
		['usedFeature', fromDate, toDate],
		async () => {
			setLoading(true);
			const res = await statisticalApi.v1UsedFeature({
				from: moment(fromDate).format('YYYY-MM-DD'),
				to: moment(toDate).format('YYYY-MM-DD'),
			});
			return res.data;
		},
		{
			onSuccess: () => {
				setLoading(false);
			},
			onError: () => {
				setLoading(false);
			},
			enabled: !!fromDate && !!toDate,
		}
	);

	const viewFeatureQuery = useQuery(
		['viewFeature', fromDate, toDate],
		async () => {
			setLoading(true);
			const res = await statisticalApi.v1ViewFeature({
				from: moment(fromDate).format('YYYY-MM-DD'),
				to: moment(toDate).format('YYYY-MM-DD'),
			});
			return res.data;
		},
		{
			onSuccess: () => {
				setLoading(false);
			},
			onError: () => {
				setLoading(false);
			},
			enabled: !!fromDate && !!toDate,
		}
	);

	useEffect(() => {
		document.title = 'Statistical';
	}, []);

	const handleDateChange = (dates) => {
		if (dates) {
			setFromDate(dates[0]);
			setToDate(dates[1]);
			queryClient.invalidateQueries(['usedFeature']);
			queryClient.invalidateQueries(['viewFeature']);
		}
	};

	const disableFutureDates = (current) => {
		return current && current > moment().endOf('day');
	};

	return (
		<>
			<div className='fade-in'>
				<PageHeader
					title='Thống kê'
					extra={
						<div>
							<RangePicker
								placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
								defaultValue={[fromDate, toDate]}
								onChange={handleDateChange}
								format='DD/MM/YYYY'
								disabledDate={disableFutureDates}
							/>
						</div>
					}
				/>

				<div className='p-4 space-y-4'>
					<Row gutter={[16, 16]}>
						<Col className='gutter-row' xs={24} md={12} lg={12} span={6}>
							{loading ? (
								<LoadingComponents />
							) : (
								<LineChartComponents
									title={'Số lượt user sử dụng tính năng/tháng'}
									data={
										usedFeatureQuery?.data?.map((item: any) => ({
											x: item?.month,
											y: Number(item?.total),
											series: 'Số lượt user sử dụng tính năng/tháng',
										})) || []
									}
									xField='x'
									yField='y'
									seriesField='series'
								/>
							)}
						</Col>
						<Col className='gutter-row' xs={24} md={12} lg={12} span={6}>
							{loading ? (
								<LoadingComponents />
							) : (
								<LineChartComponents
									title={'Số lượng jobseeker từ website optimize/tháng'}
									data={
										viewFeatureQuery?.data?.map((item: any) => ({
											x: item?.month,
											y: Number(item?.webCount),
											series: 'Số lượng jobseeker từ website optimize/tháng',
										})) || []
									}
									xField='x'
									yField='y'
									seriesField='series'
								/>
							)}
						</Col>
						<Col className='gutter-row' xs={24} md={12} lg={12} span={6}>
							{loading ? (
								<LoadingComponents />
							) : (
								<LineChartComponents
									title={'Số lượng jobseeker từ social vào hệ thống/tháng'}
									data={
										viewFeatureQuery?.data?.map((item: any) => ({
											x: item?.month,
											y: Number(item?.socialCount),
											series: 'Số lượng jobseeker từ social vào hệ thống/tháng',
										})) || []
									}
									xField='x'
									yField='y'
									seriesField='series'
								/>
							)}
						</Col>

						<Col className='gutter-row' xs={24} md={12} lg={12} span={6}>
							{loading ? (
								<LoadingComponents />
							) : (
								<LineChartComponents
									title={'Số lượng jobseeker từ email (Dịch vụ gửi mail)/tháng'}
									data={
										viewFeatureQuery?.data?.map((item: any) => ({
											x: item?.month,
											y: Number(item?.emailCount),
											series: 'Số lượng jobseeker từ email (Dịch vụ gửi mail)/tháng',
										})) || []
									}
									xField='x'
									yField='y'
									seriesField='series'
								/>
							)}
						</Col>
						<Col className='gutter-row' xs={24} md={12} lg={12} span={6}>
							{loading ? (
								<LoadingComponents />
							) : (
								<LineChartComponents
									title={'Tổng số lượng jobseeker từ website, email, socials vào hệ thống/tháng'}
									data={
										viewFeatureQuery?.data?.map((item: any) => ({
											x: item?.month,
											y: Number(item?.total),
											series: 'Tổng số lượng jobseeker từ website, email, socials vào hệ thống/tháng',
										})) || []
									}
									xField='x'
									yField='y'
									seriesField='series'
								/>
							)}
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default Statistical;
