import {
	CloudUploadOutlined,
	CustomerServiceOutlined,
	FileDoneOutlined,
	GroupOutlined,
	HomeFilled,
	LineChartOutlined,
	LogoutOutlined,
	MailOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	OrderedListOutlined,
	ProfileOutlined,
	UserOutlined,
	UsergroupAddOutlined
} from '@ant-design/icons';
import { faBuilding, faComments, faFlag } from '@fortawesome/free-regular-svg-icons';
import {
	faCartShopping,
	faEnvelopesBulk,
	faMoneyBill,
	faQuoteLeft,
	faUsersBetweenLines,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Layout, Menu } from 'antd';
import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../asset/logo-white.png';
import { useAuthUser } from '../../contexts';
import { adminRouter } from '../../routes';

const { Sider, Content } = Layout;
// --- components

const menuList = [
	{
		icon: <HomeFilled />,
		label: 'Home',
		key: adminRouter.home.href(),
	},
	{
		icon: <LineChartOutlined />,
		label: 'Statistical',
		key: adminRouter.statistical.href(),
	},
	{
		icon: <CloudUploadOutlined />,
		label: 'Data Sale Management',
		key: adminRouter.dataSaleManagement.href(),
	},
	{
		icon: <UserOutlined />,
		label: 'Sale Employer',
		key: adminRouter.saleMemberManagement.href(),
	},
	{
		icon: <GroupOutlined />,
		label: 'Group Sale',
		key: adminRouter.groupSaleManagement.href(),
	},
	{
		icon: <FontAwesomeIcon icon={faUsersBetweenLines} />,
		label: 'Employer',
		key: adminRouter.employerManagement.href(),
	},
	{
		icon: <CustomerServiceOutlined />,
		label: 'White List',
		key: adminRouter.whiteListManagement.href(),
	},
	{
		icon: <MailOutlined />,
		label: 'Group Mail',
		key: adminRouter.groupMailManagement.href(),
	},
	{
		icon: <FontAwesomeIcon icon={faEnvelopesBulk} />,
		label: 'Mail Campaigns',
		key: adminRouter.sendMailManagement.href(),
	},
	{
		icon: <ProfileOutlined />,
		label: 'Cv Management',
		key: '#',
		children: [
			{
				icon: <OrderedListOutlined />,
				label: 'List Cv',
				key: adminRouter.cvManagement.href(),
			},
			{
				icon: <FileDoneOutlined />,
				label: 'Job list',
				key: adminRouter.CvApplied.href(),
			},
			{
				icon: <UsergroupAddOutlined />,
				label: 'Permission',
				key: adminRouter.permissionCv.href(),
			},
			{
				icon: <FontAwesomeIcon icon={faQuoteLeft} />,
				label: 'Point',
				key: adminRouter.cvPoint.href(),
			},
			{
				icon: <FontAwesomeIcon icon={faFlag} />,
				label: 'Resume Report',
				key: adminRouter.reportCv.href(),
			},
		],
	},
	{
		icon: <FontAwesomeIcon icon={faBuilding as any} />,
		label: 'Company',
		key: adminRouter.companyPage.href(),
	},
	{
		icon: <FontAwesomeIcon icon={faMoneyBill as any} />,
		label: 'Purchase Order',
		key: adminRouter.purchaseOrderManagement.href(),
	},
	{
		icon: <FontAwesomeIcon icon={faCartShopping as any} />,
		label: 'Sale Order',
		key: adminRouter.saleOrderManagement.href(),
	},
	{
		icon: <FontAwesomeIcon icon={faComments as any} />,
		label: 'Feedback Optimize CV',
		key: adminRouter.feedbackOptimizeCVPage.href(),
	},
	{
		icon: <FontAwesomeIcon icon={faComments as any} />,
		label: 'Feedback',
		key: adminRouter.feedBackPage.href(),
	},

	{
		icon: <LogoutOutlined />,
		label: 'Logout',
		key: 'logout',
	},
];

interface AdminLayoutProps {}

export const AdminLayout: React.FC<AdminLayoutProps> = ({}) => {
	const [collapsed, setCollapsed] = React.useState(false);
	const location = useLocation();
	const navigator = useNavigate();
	const userContext = useAuthUser();

	const _handleOnLogout = () => {
		userContext.handleLogout();
	};

	return (
		<>
			<div className='root'>
				<Layout>
					<Sider width={250} theme='light' trigger={null} collapsible collapsed={collapsed} className='flex flex-col'>
						<div className='flex-1 w-full h-full min-h-screen bg-white shadow-lg'>
							<div className='flex items-center justify-center w-full h-16 px-4 py-4 bg-gray-900 logo'>
								{collapsed ? (
									<>
										<div className='flex items-center justify-center text-white'>AD</div>
									</>
								) : (
									<>
										<img src={logo} alt='logo-sale' className='w-7 h-7 mr-2' />
										<div className='flex items-center justify-center text-white'>Admin Dashboard</div>
									</>
								)}
							</div>
							<Menu
								mode='inline'
								onClick={({ key }) => {
									if (key === 'logout') {
										_handleOnLogout();
										return;
									}
									navigator(key);
								}}
								items={menuList as any}
								selectedKeys={[location.pathname]}
							/>
						</div>
					</Sider>
					<Layout>
						<div className='flex items-center justify-between h-16 p-2 space-x-8 bg-white'>
							<Button
								onClick={() => setCollapsed(!collapsed)}
								type='primary'
								icon={collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
								size='middle'
							/>
							<div className='flex-1'></div>

							<div></div>
						</div>
						<Content>
							<div className='min-h-screen'>
								<Outlet />
							</div>
						</Content>
					</Layout>
				</Layout>
			</div>
		</>
	);
};
