/* eslint-disable @typescript-eslint/no-unused-vars */
import { http } from './http';
import _get from 'lodash/get';

export const statisticalApi = {
	v1UsedFeature: async (params: { from: any; to: any }): Promise<any> => {
		const res = await http.get(
			`/v1/api/optimize-cv/anynomous-cv/used-feature/statistics?fromDate=${params.from}&toDate=${params.to}`
		);
		return res.data;
	},
	v1ViewFeature: async (params: { from: any; to: any }): Promise<any> => {
		const res = await http.get(
			`/v1/api/optimize-cv/anynomous-cv/views/statistics?fromDate=${params.from}&toDate=${params.to}`
		);
		return res.data;
	},
};
